.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0 auto;
  background-color: var(--secondary-color);
  max-width: 1800px;
  min-height: 100%;
}
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

:root {
  --default-color: #4d0013;
  --secondary-color: #F7E7CE;
  --default-font: 'Dancing Script', cursive; 
  --secondary-font: 'Lora', serif; 
  --default-desktop-h1-font-size: 70px;
  --default-mobile-h1-font-size: 35px;
  --default-desktop-h2-font-size: 50px;
  --default-mobile-h2-font-size: 30px;
  --default-desktop-h3-font-size: 40px;
  --default-mobile-h3-font-size: 25px;
  --default-desktop-h4-font-size: 30px;
  --default-mobile-h4-font-size: 20px;
  --default-desktop-p-font-size: 20px;
  --default-mobile-p-font-size: 15px;
  --default-desktop-span-font-size: 20px;
  --default-mobile-span-font-size: 15px;
}
html {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

body {
  color: var(--default-color);
  background-color: var(--secondary-color);
  font-family: var(--default-font);
  font-style: cursive;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

h1 {
  font-size: var(--default-desktop-h1-font-size);
  font-family: var(--default-font);
  font-weight: 300;
  margin: 0;
  padding: 20px 0;
}

h2 {
  font-size: var(--default-desktop-h2-font-size);
  font-family: var(--default-font);
  font-weight: 200;
  margin: 0;
  padding: 20px 0;
}

h3 {
  font-size: var(--default-desktop-h3-font-size);
  font-family: var(--default-font);
  font-weight: 200;
  margin: 0;
  padding: 0;
}

h4 {
  font-size: var(--default-desktop-h4-font-size);
  font-family: var(--default-font);
  font-weight: 200;
  margin: 0;
  padding: 0;
}

p {
  font-size: var(--default-desktop-p-font-size);
  font-family: var(--secondary-font);
  font-weight: 100;
  font-style: italic;
  margin: 0;
  padding: 0;
}

span {
  font-size: var(--default-desktop-span-font-size);
  font-family: var(--secondary-font);
  font-weight: 100;
  margin: 0;
  padding: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: var(--default-color);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

header {
  text-align: center;
}

.section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 200px 0;
}

.content {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.image {
  width: 50%;
  max-height: 700px;
  object-fit: cover;
}

.text {
  width: 50%;
  padding: 20px;
  color: var(--default-color);
}

@media (max-width: 768px) {
  h1 {
    font-size: var(--default-mobile-h1-font-size);
  }
  
  h2 {
    font-size: var(--default-mobile-h2-font-size);
  }
  
  p {
    font-size: var(--default-mobile-p-font-size);
  }

  span {
    font-size: var(--default-mobile-span-font-size);
  }

  .section {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .content {
    flex-direction: column;
    width: 100%;
  }

  .image {
    width: 100%;
    max-height: 300px;
  }

  .text {
    width: 100%;
    padding: 10px;
  }
}

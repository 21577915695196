.wedding-container {
  width: 100vw;
  background-color: var(--default-color);
  justify-items: center;
  padding: 50px 0;
  margin: 0 auto;
  color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}

.wedding-card-conteiner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin: 0 100px;
}

.wedding-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 30vw;
  background-color: var(--secondary-color);
  border-radius: 10px;
  overflow: hidden;
  justify-content: space-between;
  color: var(--default-color);
}

.card-image {
  height: 50%;
  padding: 20px;
  justify-content: space-between;
  background-size: cover;
  background-position: center;
}

.card-footer {
  height: 40px;
  padding: 20px;
  text-align: center;
}

.card-content {
  flex: 1;
  padding: 10px;
  width: 70%;
  text-align: center;
}

.wedding-card img {
  width: 25vw;
  height: 25vw;
  max-width: 300px;
  max-height: 300px;
  border-radius: 5%;
}

.wedding-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.wedding-button {
  text-align: center;
  margin: 20px;
}

.wedding-button-container {
  color: var(--default-color);
}

.wedding-button-container:hover {
  color: var(--secondary-color);
}

.wedding-button .button {
  display: inline-block;
  text-decoration: none;
  background-color: var(--secondary-color);
  padding: 10px 10px;
  border-radius: 5px; 
  font-size: 16px;
  font-weight: bold; 
  border: 1px solid transparent;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: var(--default-color);
  border: 1px solid var(--secondary-color);
}

.wedding-button .button p {
  margin: 0;
  padding: 5px 0;
}

.wedding-button .button:hover h4,
.wedding-button .button:hover p {
  color: var(--secondary-color);
}

.google-maps-icon {
  width: 50px;
}

@media (max-width: 768px) {
  .wedding-card img {
    width: 100vw;
    height: 100vw;
    max-width: 250px;
    max-height: 250px;
  }

  .card-image {
    height: 45%;
  }

  .card-footer {
    height: 50px;
  }

  .wedding-card-conteiner {
    flex-direction: column;
    margin: 0;
  }

  .wedding-card {
    width: 80%;
    height: 500px;
  }
  .button {
    width: 80%;
    font-size: 1rem;
  }
}
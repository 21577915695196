.image-container {
  width: 100%;
  height: 100%;
  margin-bottom: -1%;
  background-color: var(--secondary-color);
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

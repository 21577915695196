.timeline-container {
  background-color: var(--secondary-color);
  width: 90vw;
  margin: 0 auto;
  padding: 0 5vw;
}

.timeline {
  position: relative;
  padding: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  margin-bottom: 100px;
}

.timeline-title {
  color: var(--default-color);
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.timeline-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  gap: 30px;
  min-height: 300px;
  margin: 40px 0;
}

.timeline-item .image-container {
  height: 300px;
  width: 300px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  overflow: hidden;
  margin: 0 auto;
  padding: 0;
  text-align: center;
}

.timeline-item .image {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.time-text-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 400px;
  width: 40vw;
  padding: 0;
  color: var(--default-color);
  gap: 20px;
}

.time-text-date {
  font-family: var(--default-font);
  font-size: 20px;
}

.timeline::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 4px;
  background: var(--default-color);
  transform: translateX(-50%);
  display: block;
}

.point {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: block;
}

.point:nth-child(1) {
  top: 8%;
}

.point:nth-child(2) {
  top: 28%;
}

.point:nth-child(3) {
  top: 48%;
}

.point:nth-child(4) {
  top: 68%;
}

.point:nth-child(5) {
  top: 90%;
}

.timeline-item:nth-child(even) {
  flex-direction: row-reverse;
}

.timeline-item:nth-child(even) .text-container {
  text-align: right;
}

@media (max-width: 768px) {

  .timeline {
    width: 90%;
  }

  .timeline-item {
    display: block;
    width: 100%;
    justify-items: center;
  }

  .time-text-container {
    width: 100%;
    max-width: 100%;
    text-align: center;
  }

  .timeline::before {
    display: none;
  }

  .point {
    display: none;
  }
}

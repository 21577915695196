.carousel-container {
  width: 100vw;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: -1%;
}

.carousel {
  display: inline-flex;
  animation: scroll-back-and-forth 40s linear infinite;
}

.carousel-img {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.carousel-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  transition: opacity 1s ease-in-out;
  z-index: 1;
}

.carousel-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid var(--secondary-color);
  padding: 50px;
  text-align: center;
  color: var(--secondary-color);
  text-align: center;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.2);
}

.carousel-text p {
 font-size: 40px;
}

.carousel-line {
  border-bottom: 1px solid var(--secondary-color);
  width: 100%;
}

@keyframes scroll-back-and-forth {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-300vw);
  }
  100% {
    transform: translateX(0);
  }
}


@media (max-width: 768px) {
  .carousel-img {
    width: 100vw;
    height: 100vh;
    object-fit: cover; 
  }

  .carousel-text {
    padding: 20px;
  }
  
  .carousel-text p {
    font-size: 25px;
   }
}

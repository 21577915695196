.about-us-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;
  margin: 0;
  padding: 100px 0;
}

.about-us-container {
  margin: 0;
  display: grid;
  background-color: var(--secondary-color);
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  width: 60%;
  height: 60%;
  gap: 10px;
}

.square {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: var(--secondary-color);
}

.image-square {
  background-size: cover;
  background-position: center;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.text-square {
  background-color: var(--default-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.phone-link {
  color: var(--secondary-color);
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.phone-icon {
  width: 20px;
  height: 20px;
  fill: var(--secondary-color);
}

@media screen and (max-width: 768px) {
  .about-us-container {
    width: 90%;
    height: 90%;
  }
}
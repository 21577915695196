.counter-container {
  display: flex;
  flex-direction: column;
  background-color: var(--default-color);
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 130px 0;
}

.counter-container h2 {
  padding: 0;
}

.counter-container h3 {
  color: var(--secondary-color);
}

.counter-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 70px;
  background-color: var(--default-color);
  color: var(--secondary-color);
  width: 100%;
}

.counter-section-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.counter-line {
  border-bottom: 1px solid var(--secondary-color);
  width: 70%;
}

@media (max-width: 768px) {
  .counter-container {
    width: 100%;
    padding: 50px 0;
  }
  
  .counter-section {
    gap: 20px
  }

  .counter-container2 {
    gap: 20px;
  }
}
